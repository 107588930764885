
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ReceiptsTable from "@/components/reusable/ReceiptsTable.vue";
import InvoicesTable from "@/components/reusable/InvoicesTable.vue";
import PolicySummary from "@/components/reusable/policy-information/PolicySummary.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "client-house-insurance-policy-information",
  components: {
    ReceiptsTable,
    InvoicesTable,
    PolicySummary,
  },
  data() {
    return {
      // policySummary: {},
      // buildings: [],
      // receipts: [],
      // invoices: [],
      publicId: this.$route.params.publicId,
      // route: "",
    };
  },
  setup() {
    const router = useRouter();
    const tabIndex = ref(0);

    const policySummary = ref({});
    const buildings = ref([]);
    const receipts = ref([]);
    const invoices = ref([]);
    const publicId = router.currentRoute.value.params.publicId;

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    const policyDetailsRoute = (role: string): string => {
      let route = "";

      switch (role) {
        case variables.INDIVIDUAL_CLIENT_USER_ROLE:
          route = `${variables.POLICIES_ROUTE}/${publicId}`;
          break;
        case variables.BROKER_USER_ROLE:
          route = `${variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE}/${publicId}`;
          break;
        case variables.ADMIN_USER_ROLE:
          route = `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}/${publicId}`;
          break;
        case variables.SUPER_ADMIN_USER_ROLE:
          route = `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}/${publicId}`;
          break;
        default:
          route = "";
      }

      return route;
    };

    // Function to get underwriters
    const getPolicyDetails = async (role) => {
      const route = policyDetailsRoute(role);

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(route)
          .then(({ data }) => {
            policySummary.value = data.data.policySummary;
            buildings.value = data.data.items;
            receipts.value = data.data.receipts;
            invoices.value = data.data.invoices;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      getPolicyDetails(JwtService.getUserRole() as string);

      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Owned Building", [
        "Policy",
        "Policy Information",
        "House Insurance",
      ]);
    });

    return {
      policyDetailsRoute,
      tabIndex,
      config,
      setActiveTab,
      policySummary,
      buildings,
      receipts,
      invoices,
    };
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    //Set page title
    document.title =
      "Owned Building House Insurance Policy Information | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    downloadPolicyDocuments(publicId, reference) {
      const submitButton1 = document.getElementById("policy_download_btn");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        // Delete cart item
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(`${variables.POLICIES_ROUTE}/${publicId}/documents`)
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");

              // console.log(response.data);
              const blob = new Blob([response.data], {
                type: "application/octet-stream",
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download =
                "Motor-insurance(" + reference + ")-policy-documents.zip";

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
  },
});
